<template>
    <div class="queues">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Cleanup</a></li>
            </ul>
        </nav>

        <h1 class="title mb-6">Cleanup</h1>
        <p class="subtitle">TODO: Create some useful commands for analyzing data and cleaning up missing references.</p>
    </div>
</template>

<script>
    export default {
        name: 'Cleanup',
        data() {
            return {
                isLoading: true
            }
        },
    };
</script>

<style scoped>
</style>

